<template>
  <div class="row feed boxw mb-1 " >
    <div class="box">
      <h3 class="titulo">Mensagens</h3>
      <div class="pr pd-10">
        <div class="input-group col-12 search-bar">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input type="search" placeholder="Search..." aria-describedby="button-addon5" style="" class="form-control pl-3" v-model="search">
        </div>
        <div class="mt-3 mb-4" style="display:flex;overflow-y:hidden">
          <div class="avatar-list mt-1 mb-1" v-for="(dt, index) in res" :key="index">
            <router-link :to="'/message/' + (dt.from_username === user.username ? dt.to + '/' + dt.to_username : dt.from + '/' + dt.from_username)" v-show="!search.length || search.length && (dt.from_username === user.username ? dt.to_username : dt.from_username).toLowerCase().search(search.toLowerCase()) !== -1">
              <div class="profile-image">
              <img v-lazy="'/users/' + (dt.from_username === user.username ?  Math.floor(dt.to /1000) + '/' + dt.to_username : Math.floor(dt.from /1000) + '/' + dt.from_username) + '/imgp.jpg'" alt="">
              <i class="fas fa-circle "></i>
              </div>
              <div>{{ dt.from_username === user.username ? dt.to_username : dt.from_username }}</div>
            </router-link>
          </div>
        </div>

        <div class="message-list pr" v-for="(dt, index) in res" :key="index" v-show="!search.length || search.length && (dt.from_username === user.username ? dt.to_username : dt.from_username).toLowerCase().search(search.toLowerCase()) !== -1">
          <a class="text-danger del-messages" @click.stop="delMessage(dt.from_username === user.username ? dt.to_username : dt.from_username, dt.id, index)"><i class="fa fa-trash"></i></a>
          <router-link :to="'/message/' + (dt.from_username === user.username ? dt.to + '/' + dt.to_username : dt.from + '/' + dt.from_username)">
            <div class="message-list-item">
              <div class="profile-image">
                <img v-lazy="'/users/' + (dt.from_username === user.username ? Math.floor(dt.to /1000) + '/' + dt.to_username : Math.floor(dt.from /1000) + '/' + dt.from_username) + '/imgp.jpg'" alt="">
                <div class="badge">5</div>
              </div>
              <div class="msg-info" style="">
                <span>{{ dt.from_username === user.username ? dt.to_username : dt.from_username }}</span>
                <p v-html="dt.message"></p>
              </div>
            </div>
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { messages, messagesDelete } from './../resources/fn'
import { success, error } from '../resources/functions'

export default {
  name: 'messages',
  data () {
    return {
      res: [],
      search: ''
    }
  },
  beforeMount () {
    this.SET_LOAD(true)
    messages(this.axios, {}).then((res) => {
      this.res = res.data
      this.SET_LOAD()
    }, (res) => {
      this.SET_LOAD()
    })
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD'
    ]),
    delMessage (user, id, index) {
      messagesDelete(this.axios, id, user).then((res) => {
        success(this, 'conversa apagada com sucesso')
        this.res.splice(index, 1)
        this.SET_LOAD()
      }, (res) => {
        error(this, 'nao foi possivel apagar conversa')
        this.SET_LOAD()
      })
    }
  }
}
</script>
